import * as React from 'react';
import { HIDE_FROM_VIEWER_SOFTWARE } from '../../utils';

import {
  InputWrapper,
  InputLabelWrapper,
  InputLabel,
  ErrorMessage,
  ITextInput,
} from './inputs';

export const RHFPasswordInput = ({
  autoComplete,
  autoFocus,
  testid,
  name,
  label,
  register,
  options,
  error,
  value,
  placeholder,
  enterKeyHint,
}: ITextInput) => (
  <InputWrapper
    error={!!error}
    filled={typeof value === 'string' ? !!value?.length : !!value}
  >
    <input
      className={HIDE_FROM_VIEWER_SOFTWARE}
      type="password"
      {...register(name, options)}
      data-testid={testid}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      placeholder={placeholder}
      enterKeyHint={enterKeyHint}
    />
    <InputLabelWrapper>
      <InputLabel>{label}</InputLabel>
    </InputLabelWrapper>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </InputWrapper>
);
