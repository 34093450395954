/**
 * React and friends
 */
import React from 'react';

/**
 * Material UI components
 */
import Alert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';

/**
 * Compile time type checking
 */
type MessageType = {
  isAlertVisible: boolean;
  isSuccess: boolean;
  autoHideDuration: number;
  message: string;
  setIsAlertVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Component for displaying messages
 */
function SnackbarMessage({
  isAlertVisible = false,
  isSuccess = true,
  autoHideDuration = 3000,
  message,
  setIsAlertVisible,
}: MessageType) {
  const severity = isSuccess ? 'success' : 'error';
  const fallbackMessage = isSuccess
    ? ''
    : 'Sorry, an error occurred, please wait a few minutes before you try again!';

  return (
    <Snackbar
      data-testid="global-message"
      open={isAlertVisible}
      autoHideDuration={autoHideDuration}
      onClose={() => {
        setIsAlertVisible(false);
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ top: '64px' }}
    >
      <Alert severity={severity}>{message || fallbackMessage}</Alert>
    </Snackbar>
  );
}

export default SnackbarMessage;
