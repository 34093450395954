export class BureauIdentificationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class BadRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class InvalidSubscriptionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class TooManyRequestsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class BorrowerCreateError extends Error {
  constructor(message: string, type: string, param?: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class BorrowerSolvePlanError extends Error {
  constructor(message: string, type: string, param?: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class ServiceUnavailableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class NotReadyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
