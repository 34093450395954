/* eslint-disable react/prop-types */
/*
 * This context is used to persist data passed between dashboard pages during route changes
 */

import * as React from 'react';

const initialState = { strategy: null, manageDebt: null, toast: null };

const DashboardContext = React.createContext(initialState);

export const useDashboardContext = () => React.useContext(DashboardContext);

export const DashboardContextProvider = ({ children }) => {
  const [state, setState] = React.useState(initialState);

  return (
    <DashboardContext.Provider value={[state, setState]}>
      {children}
    </DashboardContext.Provider>
  );
};
