import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

// Material UI
import LinkedIn from '@mui/icons-material/LinkedIn';
import Facebook from '@mui/icons-material/Facebook';
import Twitter from '@mui/icons-material/Twitter';

// Utils
import { useWindowWidth } from '../../hooks/useWindowSize';

// Components
import { Row } from '../page-components-copy/components';

const Container = styled('div')`
  @media print {
    display: none;
  }
  background-color: #000;
  padding: 26px 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    flex-direction: row;
    padding: 93px 0;
  }
  & #copyright {
    font-size: 18px;
    line-height: 32px;
  }
`;

const Content = styled('nav')`
  display: flex;
  margin: auto;
  flex-direction: column;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    flex-direction: row;
    max-width: 1200px;
  }
  & ul {
    list-style: none;
    margin: 0;
    & * {
      color: #fff;
    }
    & li {
      font-family: 'Majorant-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      & a {
        text-decoration: none;
        padding: 4px 4px 4px 0;
      }
    }
    & p {
      font-size: 14px;
    }
  }
`;

const BigText = styled('span')`
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    font-size: 32px;
    line-height: 47px;
  }
`;

const LinksContainer = styled(Row)`
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 42px;
  flex-direction: column;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;
  }
`;

const LinkRow = styled('ul')`
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    flex-direction: column;
  }
  & a {
    text-decoration: none;
  }
`;

const NYText = styled('li')<{ isHomepage: boolean }>`
  ${({ isHomepage }) => (isHomepage ? '' : 'display: none;')};
`;

const Footer = () => {
  const { isMobile } = useWindowWidth();
  const { pathname } = useLocation();
  const isHomepage = pathname === '/';
  return (
    <Container id="footer">
      <Content>
        <LinksContainer>
          <ul>
            <li>
              <a href="https://www.solve.finance/about-solve-finance">About</a>
            </li>

            <li>
              <a href="https://www.solve.finance/financial-advisor-for-your-users/">
                Partner
              </a>
            </li>

            <li>
              <a href="https://www.solve.finance/financial-advisor-for-individuals/">
                Individuals
              </a>
            </li>

            <li>
              <a href="https://www.solve.finance/resources/">Resources</a>
            </li>

            <li>
              <a href="https://www.solve.finance/privacy/">Privacy Policy</a>
            </li>

            <li>
              <a href="https://www.solve.finance/tos/">Terms of Service</a>
            </li>
          </ul>

          <LinkRow>
            <li>
              <a href="https://www.linkedin.com/company/solve-finance">
                {isMobile ? <LinkedIn fontSize="large" /> : 'LinkedIn'}
              </a>
            </li>
            <li>
              <a href="https://twitter.com/solvefinance">
                {isMobile ? <Twitter fontSize="large" /> : 'Twitter'}
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/SolveFinance/">
                {isMobile ? <Facebook fontSize="large" /> : 'Facebook'}
              </a>
            </li>
          </LinkRow>
        </LinksContainer>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ul data-testid="footerRight">
            <BigText>
              Get in touch
              {isMobile ? <br /> : ' '}
              <a href="mailto:info@solve.finance">info@solve.finance</a>
            </BigText>
          </ul>
          <ul>
            <li id="copyright">
              © Copyright {new Date().getFullYear()}, Solve Finance
            </li>
          </ul>
          <ul>
            <NYText isHomepage={isHomepage}>
              * Loans are arranged through third party lenders. This site is not
              authorized by the New York State Department of Financial Services.
              No mortgage solicitation activity or loan applications for
              properties located in the State of New York can be facilitated
              through this site.
            </NYText>
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export default Footer;
