import * as React from 'react';
import Mail from '@mui/icons-material/MailOutline';
import Phone from '@mui/icons-material/PhoneInTalk';
import {
  ButtonLink,
  Row,
} from '../../../../components/page-components-copy/components';
import { theme } from '../../../../components/styles/GlobalStyle';
import { useWindowWidth } from '../../../../hooks/useWindowSize';

const BetterContact = () => {
  const { width, isMobile } = useWindowWidth();
  const shouldCollapse = width < 1150;
  const isSmall = width < 476;
  const hideEmail = width < 380;
  let top = shouldCollapse ? '76px' : '12px';
  top = isMobile ? '2px' : top;
  let right = shouldCollapse ? '16px' : '16px';
  right = isMobile ? '64px' : right;

  return (
    <Row
      style={{
        flexDirection: shouldCollapse ? 'row-reverse' : 'row',
        marginRight: shouldCollapse ? '16px' : '0',
        position: 'absolute',
        top,
        right,
        gap: '32px',
        width: shouldCollapse ? 'auto' : 'unset',
        fontSize: shouldCollapse ? '12px' : '16px',
      }}
    >
      {!hideEmail && (
        <a
          href="mailto:support@solve.finance"
          style={{ color: shouldCollapse ? theme.gray6 : theme.gray8 }}
        >
          <Row style={{ alignItems: 'center' }}>
            <Mail style={{ marginRight: '4px' }} />
            {!isMobile && 'support@solve.finance'}
          </Row>
        </a>
      )}
      <ButtonLink
        href="tel:+12017771898"
        as="a"
        bgColor="#EB5757"
        color={theme.white}
        style={{ minWidth: '0' }}
      >
        <Phone
          style={{
            width: 'inherit',
            marginRight: '4px',
            color: theme.white,
          }}
        />
        {!isSmall && '(201) 777-1898'}
      </ButtonLink>
    </Row>
  );
};

export default BetterContact;
