import React, { useContext, useState } from 'react';

const AdditionalInfoContext = React.createContext(null);
const AdditionalInfoSetStateContext = React.createContext(null);

export const AdditionalInfoProvider = ({ children }) => {
  const [state, setState] = useState({});

  console.debug('AdditionalInfoProvider Context: ', state);

  return (
    <AdditionalInfoContext.Provider value={state}>
      <AdditionalInfoSetStateContext.Provider value={setState}>
        {children}
      </AdditionalInfoSetStateContext.Provider>
    </AdditionalInfoContext.Provider>
  );
};

export const useAdditionalInfoContext = () => {
  return useContext(AdditionalInfoContext);
};

export const useAdditionalInfoSetState = () => {
  return useContext(AdditionalInfoSetStateContext);
};
