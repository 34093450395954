/**
 * React and friends
 */
import styled from 'styled-components';

import * as PageData from './Onboarding.yaml';
import { ZeroStylesButton } from '../ZeroStylesButton';
import { theme } from '../styles/GlobalStyle';

export { PageData };
/**
 * Steps to complete screen for financial onboarding
 *
 * Stage may be undefined. All others should be populated.
 */

export const ModalBody = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 32px;
  max-width: calc(100% - 88px);
  background-color: white;
  border: 2px solid ${({ theme }) => theme.gray6};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  & button {
    margin: 0 12px;
  }
`;

export const ProgressBarContainer = styled('div')`
  position: relative;
  max-width: 80%;
  margin: 32px auto 0 auto;
  padding: 0 32px;
  @media screen and ${({ theme }) => theme.mq.desktopMin} {
    max-width: 800px;
    padding: 24px 0 12px;
  }

  @media screen and ${({ theme }) => theme.mq.tabletMax} {
    background: #f0f0f0;
    margin: -32px -24px 0;
    max-width: none;
    padding: 12px 24px;
  }
`;

export const IconButtonContainer = styled(ZeroStylesButton)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media ${theme.mq.desktopLargeMin} {
    top: 8px;
  }
`;
