// Services
import { USER_ACTIONS } from './user.actions';

// Components
import { DEBT_GROUP } from '../../utils';

export const initialState = {
  initialized: false,
  loading: false,
  user: null,
  debtHash: [],
  isSubscribed: false,
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case USER_ACTIONS.SET_USER_INFO:
      const nextUser = action.payload;
      const nextDebtHash = {
        [DEBT_GROUP.COLLECTIONS]: [],
        [DEBT_GROUP.CHARGEOFFS]: [],
      };

      nextUser.debts.forEach((debt) => {
        if (debt.isInCollection) {
          nextDebtHash[DEBT_GROUP.COLLECTIONS].push(debt);
        } else if (debt.isChargeoff) {
          nextDebtHash[DEBT_GROUP.CHARGEOFFS].push(debt);
        } else {
          nextDebtHash[debt.group] = nextDebtHash[debt.group] || [];
          nextDebtHash[debt.group].push(debt);
        }
      });

      return { ...state, user: { ...action.payload }, debtHash: nextDebtHash };
    case USER_ACTIONS.UPDATE_USER_INFO:
      return {
        ...state,
        user: {
          ...(state.user || {}),
          ...action.payload,
        },
        isSubscribed: state.isSubscribed || !!action.payload.isSubscribed,
      };
    case USER_ACTIONS.IS_INITIALIZED:
      return { ...state, initialized: true };
    case USER_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload };
    case USER_ACTIONS.SET_GOAL:
      return { ...state, user: { ...state.user, goal: action.payload } };
    case USER_ACTIONS.SET_BUREAU_PROVIDER_REF:
      if (!state.user) return state;

      return {
        ...state,
        user: { ...state.user, bureauProviderRef: action.payload },
      };
    case USER_ACTIONS.SET_HAS_BUREAU_AUTH:
      return {
        ...state,
        user: { ...state.user, hasBureauAuth: action.payload },
      };
    case USER_ACTIONS.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
