import React, { useEffect } from 'react';

// Material UI
import Grid from '@mui/material/Grid';

// Data
import { SUPPORT_EMAIL } from '../../_configuration';
import { recordEvent } from '../../services/user.service';

// Components
import { MailLink, Text } from '../_core/typography.components';
import { useAuthContext } from '../../providers/auth.provider';

const NotFoundPage = () => {
  const { firebaseUser } = useAuthContext();

  useEffect(() => {
    if (firebaseUser) {
      recordEvent({
        eventName: 'Page Not Found Error',
        firebaseUser,
        props: { path: window.location.pathname },
      });
    }
  }, [firebaseUser]);

  return (
    <>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <Text large align="center">
          <h1>Sorry, we couldn't find that page.</h1>
        </Text>
        <Text align="center">
          Go to <a href="/">Solve's home page</a> or contact us by email at{' '}
          <MailLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</MailLink>{' '}
          with any other issues or questions.
        </Text>
      </Grid>
    </>
  );
};

export default NotFoundPage;
