title: complete your financial onboarding
subtitle: steps to complete
name: Debt Optimizer
complete: false
preQuestions: false
stages:
  - name: personal information
    complete: false
    steps:
      - title: personal information
        verify: [firstName, lastName, dob]
        complete: false
        component: PersonalInfo
        props:
          heading: Tell us a little about yourself.
          description: ''
          firstName: ''
          lastName: ''
          dob: ''
  - name: living information
    complete: false
    steps:
      - title: living information
        verify: [currentAddress, consent]
        complete: false
        component: LivingInfo
        props:
          description: ''
          heading: Where do you live?
          address:
            street: ''
            city: ''
            zipCode: ''
            state: ''
          consent: false
      - title: own or rent
        verify: [ownOrRent, rent, homeValue]
        complete: false
        component: RentInfo
        props:
          heading: What is your living situation?
          description: ''
          ownOrRent: ''
          rent: 0
          homeValue: 0
          options:
            - value: Rent
              label: I rent.
            - value: Own
              label: I own my home/other.
  - name: income
    complete: false
    steps:
      - title: income
        verify: [snapshots]
        complete: false
        component: IncomeInfo
        props:
          heading: What is your annual income?
          description: ''
          income: 0
  - name: credit check
    complete: false
    steps:
      - title: credit check
        verify: [creditInfos]
        complete: false
        component: ConsentInfo
        props:
          heading: Do we have your consent to do a credit check?
          ssn: ''
          description: |
            Your credit score will not be affected. This allows us to retrieve your loan details and credit score.
      - title: security questions
        verify: [securityQuestions]
        complete: false
        component: SecurityQuestions
        props:
          heading: Let's confirm it's you
          description: |
            Answer these security questions from Transunion so we can order your credit report.
          answers: ['', '', '']
          questions:
            - text: question 1
              answers: [a, b, c]
            - text: question 2
              answers: [a, b, c]
            - text: question 3
              answers: [a, b, c]
