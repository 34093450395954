// Analytics
import { Capacitor } from '@capacitor/core';
import { trackImpression } from './segment';
import Sentry from './sentry';

const SENSITIVE_PROPS = [
  'idToken',
  'ssn',
  'lastFourSSN',
  'primaryPhone',
  'currentAddress',
  'firstName',
  'lastName',
  'authToken',
  'questions',
  'answers',
  'dob',
  'email',
  'salt',
  'names',
  'passwordHash',
  'providerUserInfo',
  'password',
];

const LOG_ROCKET_KEY = process.env.LOG_ROCKET;

if (LOG_ROCKET_KEY && window.LogRocket && !Capacitor.isNativePlatform()) {
  window.LogRocket.init(LOG_ROCKET_KEY, {
    dom: {
      inputSanitizer: true,
    },

    network: {
      requestSanitizer: (request) => {
        if (request.headers.Authorization?.indexOf('Bearer') !== -1) {
          request.headers.Authorization = 'Bearer REDACTED';
        }

        if (request.body) {
          try {
            const body =
              typeof request.body === 'object'
                ? request.body
                : JSON.parse(request.body);

            SENSITIVE_PROPS.forEach((prop) => {
              sanitizeParam(prop, body);
            });

            request.body = JSON.stringify(body);
          } catch (e) {
            // Do nothing...
          }
        }

        return request;
      },

      responseSanitizer: (response) => {
        if (response.headers.Authorization?.indexOf('Bearer') !== -1) {
          response.headers.Authorization = 'Bearer REDACTED';
        }

        if (response.body) {
          try {
            const body =
              typeof response.body === 'object'
                ? response.body
                : JSON.parse(response.body);

            SENSITIVE_PROPS.forEach((prop) => {
              sanitizeParam(prop, body);
            });

            // Handle Google Identity Toolkit API
            if (body.kind && body.users) {
              body.users = 'REDACTED';
            }

            response.body = JSON.stringify(body);
          } catch (e) {
            // Do nothing, we expect to hit here on "Unauthorized" responses...
          }
        }

        return response;
      },
    },
  });

  // Integrate with Segment
  window?.LogRocket.getSessionURL(function (sessionURL) {
    trackImpression('LogRocket', { sessionURL });
  });

  // Integrate with Sentry
  window?.LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

/**
 * Redact the specified property from the object
 * @param property - The property to redact
 * @param state - The object to redact the property from
 */
function sanitizeParam(property, state) {
  if (Object.prototype.hasOwnProperty.call(state, property)) {
    state[property] = 'REDACTED';
  }
}

export default window?.LogRocket;
