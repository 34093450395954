import { useState, useEffect } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

const hasWindow = typeof window !== 'undefined';

const getScrollPosition = () =>
  hasWindow ? { x: window.scrollX, y: window.scrollY } : { x: 0, y: 0 };

export const useScrollPosition = (): ScrollPosition => {
  const [position, setPosition] = useState<ScrollPosition>(getScrollPosition);

  useEffect(() => {
    let requestRunning: number | null = null;
    const handleScroll = () => {
      if (hasWindow && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    };
    if (hasWindow) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return position;
};
