import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { handleTap } from '../../../../utils';

const Accordion = styled('ul')<{ expanded: boolean }>`
  display: ${({ expanded }) => (expanded ? 'flex' : 'none')};
  visibility: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
  flex-direction: column;
  padding: 0;
  & a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }
`;

const Container = styled('li')`
  & * {
    color: white;
  }
  height: auto;
  min-height: 40px;
  margin: 0 0 8px 0 !important;
`;

const NavButton = styled('button')`
  background-color: transparent;
  border: none;
  border-width: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
  height: 40px;
`;

export const Title = styled('span')`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
`;

const Subtext = styled('span')`
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
`;

export const AccordionNav = ({
  title,
  links,
  toggleDrawer,
}: {
  title?: string;
  links:
    | { to: string; title: string; subtext: string }[]
    | { to: string; title: string }[];
  toggleDrawer: () => void;
}) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Container key={title}>
      <NavButton onClick={() => setExpanded(!expanded)}>
        <div>
          <Title>{title}</Title>
        </div>
        <div>{expanded ? <ExpandLess /> : <ExpandMore />}</div>
      </NavButton>
      <Accordion expanded={expanded}>
        {links.map(({ to, title, subtext }) =>
          to.startsWith('http') ? (
            <a
              href={to}
              onClick={() => {
                setExpanded(false);
                toggleDrawer();
              }}
              key={to}
              style={{ height: '64px', width: '100%', marginLeft: '42px' }}
            >
              <Title>{title}</Title>
              <Subtext>{subtext}</Subtext>
            </a>
          ) : (
            <Link
              to={to}
              onClick={() => {
                setExpanded(false);
                toggleDrawer();
              }}
              key={to}
              style={{ height: '64px', width: '100%', marginLeft: '42px' }}
              tabIndex={0}
            >
              <Title>{title}</Title>
              <Subtext>{subtext}</Subtext>
            </Link>
          )
        )}
      </Accordion>
    </Container>
  );
};
