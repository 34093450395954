import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../styles/GlobalStyle';
import { Row } from './components';

const IconBox = styled('div')<{ smallIcon?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 55px;
  min-width: 55px;
  @media ${theme.mq.desktopLargeMin} {
    width: ${({ smallIcon }) => (smallIcon ? '51px' : '109px')};
    min-width: ${({ smallIcon }) => (smallIcon ? '51px' : '109px')};
    height: ${({ smallIcon }) => (smallIcon ? '55px' : '102px')};
    min-height: ${({ smallIcon }) => (smallIcon ? '55px' : '102px')};
  }
`;

const ListText = styled('p')`
  font-family: 'Majorant-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  @media ${theme.mq.desktopLargeMin} {
    font-size: 23px;
  }
  padding: 0;
  margin: 0 0 0 25px;
`;

const StyledRow = styled(Row)`
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 20px;
  @media ${theme.mq.desktopLargeMin} {
    padding: 0;
  }
`;

export const IconListItem = ({
  icon,
  text,
  smallIcon,
}: {
  icon: React.ReactNode;
  text: string | React.ReactNode;
  smallIcon?: boolean;
}) => {
  return (
    <StyledRow>
      {icon && <IconBox smallIcon={smallIcon}>{icon}</IconBox>}
      <ListText>{text}</ListText>
    </StyledRow>
  );
};
