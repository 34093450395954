import { Modal as MuiModal, ModalProps } from '@mui/material';
import styled from 'styled-components';

// Utils
import { theme } from '../../styles/GlobalStyle';

export const Modal = styled(MuiModal)`
  @media screen and ${theme.mq.tabletMax} {
    & > div:nth-child(3) {
      top: 64px;
      transform: translate(-50%, 0);
  }
`;
