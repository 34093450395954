import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Material UI
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { handleTap } from '../utils';

// Components
const BackButtonStyled = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > .MuiSvgIcon-root {
    height: 0.5em;
  }
`;

const BackButton = () => {
  const navigate = useNavigate();

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <BackButtonStyled data-testid="back-button" onClick={onGoBack}>
      <ArrowBackIosOutlinedIcon style={{ width: '24px', height: '24px' }} />
    </BackButtonStyled>
  );
};

export default BackButton;
