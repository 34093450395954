import { IOnboardingData } from '../providers/onboarding/onboarding.actions';
import { UserData } from '../components/Optimizer/types';
import {
  ASSET_TYPE,
  EXPENSE_TYPE,
  INCOME_TYPE,
} from '../services/financial-snapshot.service';

export const mapOnboardingToUserCreate = (data: IOnboardingData) => {
  const result = {
    goal: data.goal,
    source: data.source,
    sendEmail: data.sendEmail,
    sendSMS: data.sendSMS,
    firstName: data.personalInfo.firstName,
    lastName: data.personalInfo.lastName,
    phone: data.personalInfo.primaryPhone,
    address: {
      line1: data.personalInfo.address.street,
      city: data.personalInfo.address.city,
      stateAbbr: data.personalInfo.address.state,
      zipCode: data.personalInfo.address.zipCode,
    },
    isRenting: data.livingSituation.isRenting,
    financialSnapshot: {
      income: [{ type: INCOME_TYPE.PRIMARY_ANNUAL, value: data.income.annual }],
      expenses: [],
      assets: [],
    },
  };

  if (data.income.savings) {
    result.financialSnapshot.assets.push({
      type: ASSET_TYPE.LIQUID,
      value: data.income.savings,
    });
  }

  if (data.livingSituation.isRenting) {
    result.financialSnapshot.expenses.push({
      type: EXPENSE_TYPE.PRIMARY_RENT,
      value: data.livingSituation.rent,
    });
  } else {
    result.financialSnapshot.assets.push({
      type: ASSET_TYPE.HOME,
      value: data.livingSituation.homeValue,
    });
  }

  return result;
};

export const getAuthenticationErrorMsg = (error) => {
  let message;

  switch (error.code) {
    case 'auth/invalid-email': {
      message = 'Please enter a valid email address.';
      break;
    }
    case 'auth/user-not-found': {
      message = 'No user found for this email address';
      break;
    }
    case 'auth/wrong-password': {
      message = 'Incorrect password.';
      break;
    }
    case 'auth/email-already-in-use': {
      message = 'Email already in use.';
      break;
    }
  }
  // this is currently formatted for the useSnackbar hook
  return message;
};
