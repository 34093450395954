import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useWindowWidth } from '../../hooks/useWindowSize';

export const MarketingStats = () => {
  const { isMobile } = useWindowWidth();

  return (
    <Grid container display="flex" color="white">
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          height: '200px',
          width: '100vw',
          backgroundColor: '#0286E7',
          zIndex: -2,
        }}
      />
      <Grid item textAlign="center" py={4} xs={12} md={3}>
        <Box mb={2} fontSize={36}>
          $134<span style={{ fontSize: '16px' }}>/month</span>
        </Box>
        <Box>Avg. Savings Found</Box>
      </Grid>
      <Grid
        display={isMobile ? 'none' : 'initial'}
        item
        textAlign="center"
        py={4}
        xs={12}
        md={3}
      >
        <Box mb={2} fontSize={36}>
          103
        </Box>
        <Box>Avg. Potential Score Improvement</Box>
      </Grid>
      <Grid
        display={isMobile ? 'none' : 'initial'}
        item
        textAlign="center"
        py={4}
        xs={12}
        md={3}
      >
        <Box mb={2} fontSize={36}>
          +$87,606
        </Box>
        <Box>Avg. Borrowing Power</Box>
      </Grid>
      <Grid
        display={isMobile ? 'none' : 'initial'}
        item
        textAlign="center"
        py={4}
        xs={12}
        md={3}
      >
        <Box mb={2} fontSize={36}>
          +$97,641
        </Box>
        <Box>Avg. Homebuying Power</Box>
      </Grid>
    </Grid>
  );
};
