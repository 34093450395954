import React from 'react';
import { Outlet } from 'react-router';
import styled from 'styled-components';
import { Capacitor } from '@capacitor/core';

// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Utils
import { APP_TITLE } from '../../../_configuration';
import { IMAGES, getImage } from '../../../utils';

const logo = getImage(IMAGES.SOLVE_LOGO_BLACK_TEXT.ref);

const StyledLogo = styled('img')`
  width: 200px;
  margin-top: 24px;
`;

export const ErrorLayout = () => {
  return (
    <Container>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <StyledLogo src={logo} alt={APP_TITLE} />
        </Grid>
        <Outlet />
      </Grid>
    </Container>
  );
};
