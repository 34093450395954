/**
 * Component level styling
 */
import styled, { css } from 'styled-components';
/**
 * Material UI components
 */
import { Button as Base } from '@mui/material';

const ButtonStyled = styled(Base)`
  &.MuiButton-root {
    font-family: 'Majorant-Medium';
    font-size: 1em;
    width: 100%;
    border-radius: 8px;
    min-height: 43px;

    background-color: transparent;
    color: ${({ theme }) => theme.green4};
    border: 2px solid ${({ theme }) => theme.green4};

    &:hover {
      background-color: white;
    }

    ${({ variant, theme }) =>
      variant === 'contained' &&
      css`
        background-color: ${theme.green4};
        color: white;

        &:hover {
          background-color: ${theme.green4};
        }
      `}

    ${({ disabled, theme }) =>
      disabled &&
      css`
        color: #7e848a !important;
        background-color: #e1e2e4 !important;
        border: 2px solid #e1e2e4 !important;

        &:hover {
          background-color: ${theme.green4}; !important;
        }
      `}

    text-transform: none;

    & .MuiButton-contained {
      background-color: ${({ theme }) => theme.green4};
      color: white;
    }
  }
`;

export default ButtonStyled;
