import * as React from 'react';
import styled from 'styled-components';

import { useWindowWidth } from '../../hooks/useWindowSize';
import { theme } from '../styles/GlobalStyle';

const Container = styled('div')<{ mobilePositionTop: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 0 16px 0 0;
  min-height: 265px;
  padding-bottom: ${({ mobilePositionTop }) =>
    mobilePositionTop ? '0' : '225px'};
  @media ${theme.mq.desktopLargeMin} {
    padding: 0 60px;
  }
`;

const Background = styled('div')<{ color: string; height: number }>`
  position: absolute;
  left: 0;
  right: 0;
  height: ${({ height }) => `${height}px`};
  width: 100vw;
  background-color: ${({ color }) => color};
  z-index: -2;
`;

const Copy = styled('div')`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  @media ${theme.mq.desktopLargeMin} {
    flex: 1;
    margin-top: 0;
  }

  & h2 {
    font-size: 2rem;
    line-height: 150%;
    color: ${theme.white};
    width: 100%;
    margin-top: 48px;
    margin-bottom: 12px;

    @media ${theme.mq.desktopLargeMin} {
      font-size: 3rem;
    }

    span {
      font-size: 2rem;

      @media ${theme.mq.desktopLargeMin} {
        font-size: 1.5rem;
      }
    }
  }
  & p {
    font-weight: 400;
    line-height: 200%;
    font-family: 'Majorant-Light';
    font-size: 16px;
    margin-bottom: 48px;
    color: #ffffff;
    @media ${theme.mq.desktopLargeMin} {
      font-size: 18px;
      text-align: right;
    }
  }
`;

const ImageContainer = styled('div')<{ mobilePositionTop: boolean }>`
  position: relative;
  flex: 0.5;
  @media ${theme.mq.desktopLargeMin} {
    flex: 1;
  }
  & img {
    position: absolute;
    width: 100%;
    max-width: 223px;
    right: 0;
    width: fit-content;
    ${({ mobilePositionTop }) =>
      mobilePositionTop ? 'top: -50%' : 'bottom: -270px'};
    @media ${theme.mq.desktopLargeMin} {
      position: absolute;
      width: fit-content;
      right: -70px;
      top: -77px;
      max-width: 530px;
    }
  }
`;

export const ColorBanner = ({
  bannerColor,
  imageMeta,
  copyColor,
  copyAlignment,
  padding,
  copy: { headerCopy, pCopy },
}: {
  bannerColor: string;
  copyColor?: string;
  copyAlignment?: 'left' | 'right' | 'center';
  padding?: string;
  imageMeta?: { mobilePosition: 'top' | 'bottom'; src: string; alt?: string };
  copy: {
    headerCopy?: string | React.ReactNode;
    pCopy?: string | React.ReactNode;
  };
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowWidth();
  const [bgHeight, setBgHeight] = React.useState(500);
  React.useEffect(() => {
    const additionalHeight = isMobile ? 0 : 24;
    setBgHeight(containerRef?.current?.clientHeight + additionalHeight);
  }, [isMobile, containerRef.current]);
  return (
    <Container
      ref={containerRef}
      mobilePositionTop={imageMeta?.mobilePosition !== 'bottom'}
    >
      <Background color={bannerColor} height={bgHeight} />
      <Copy style={{ padding: padding || '0' }}>
        {headerCopy && <h2>{headerCopy}</h2>}
        <p style={{ color: copyColor, textAlign: copyAlignment || 'inherit' }}>
          {pCopy}
        </p>
      </Copy>
      {imageMeta && (
        <ImageContainer
          mobilePositionTop={imageMeta?.mobilePosition !== 'bottom'}
        >
          <img
            src={imageMeta?.src}
            alt={imageMeta?.alt || 'Confident Borrower'}
          />
        </ImageContainer>
      )}
    </Container>
  );
};
