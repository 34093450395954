/**
 * React and friends
 */
import React, { MouseEventHandler, FC } from 'react';

/**
 * Component level styling
 */
import styled from 'styled-components';

/**
 * Runtime input type checking
 */
import PropTypes from 'prop-types';

/**
 * Material UI components
 */
import { IconButton, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { handleTap } from '../../utils';

/**
 * A menu option type
 */
export type Option = {
  title: string;
  goTo: MouseEventHandler;
};

/**
 * Compile time type checking
 */
interface INavMenu {
  className?: string;
  isOpen: boolean;
  toggleDrawer: () => void;
}

/**
 * Side bar component which renders a list of
 * options to redirect the user
 */
const SideNav: FC<INavMenu> = ({
  className,
  isOpen,
  toggleDrawer,
  children,
}) => {
  return (
    <Drawer
      open={isOpen}
      anchor={'left'}
      className={className}
      onClose={toggleDrawer}
    >
      <div>
        <IconButton onClick={toggleDrawer} size="large">
          <CloseIcon fontSize="medium" />
        </IconButton>
      </div>
      {children}
    </Drawer>
  );
};

/**
 * Runtime input type checking definitions
 */
SideNav.propTypes = {
  /**
   * Is SideNav  visible
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Function to control state of the drawer being visible
   */
  toggleDrawer: PropTypes.func.isRequired,
};

/**
 * Default input values
 */
SideNav.defaultProps = {
  isOpen: false,
};

/**
 * Styled version of side nav component
 */
const SideNavStyled = styled(SideNav)`
  width: 100%;
  color: ${({ theme }) => theme.gray8};
  text-align: left;
  padding: 0 16px;
  & .MuiBackdrop-root {
    opacity: 0 !important;
  }
  & > .MuiDrawer-paperAnchorLeft {
    width: inherit;
    background-color: white;
    color: ${({ theme }) => theme.gray8};
    width: 72%;
    min-width: 270px;
    & > div {
      height: 80px;
      align-items: center;
      display: flex;
    }

    & ul {
      list-style: none;
      padding: 0;
      margin: 0 0 0 16px;

      & li {
        margin-bottom: 24px;
        color: ${({ theme }) => theme.gray8};
        font-family: Majorant-Medium;
        font-size: 16px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;
        text-decoration: none;
        & a {
          color: ${({ theme }) => theme.gray8};
          text-decoration: none;
        }
        & button {
          cursor: pointer;
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          font-style: normal;
          font-family: inherit;
        }
      }
    }
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.gray8};
  }
`;

/**
 * Export default is styled version
 */
export default SideNavStyled;
