import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router';
import { Capacitor } from '@capacitor/core';

// Material UI
import Box from '@mui/material/Box';

// Utils
import { ROUTE_PATHS } from '../_utils/router.utils';
import { SnackBarProvider } from '../../../components/Snackbar/useSnackBar';

// Services
import { useUserContext } from '../../../providers/user/user.provider';
import { useAuthContext } from '../../../providers/auth.provider';

// Components
import Header from '../../Layout/Navigation/Header';
import Footer from '../../Layout/Footer';
import { useWindowWidth } from '../../../hooks/useWindowSize';
import LoadingPage from '../_boilerplate/LoadingPage/loading.page';

export const HomeLayout = () => {
  window.onbeforeunload = null;

  const navigate = useNavigate();
  const { isMobile } = useWindowWidth();
  const { search, pathname } = useLocation();

  const {
    firebaseUser,
    loading: authLoading,
    initialized: authInitialized,
    handleApplyActionCode,
  } = useAuthContext();
  const {
    user,
    initialized: userInitialized,
    loading: userLoading,
  } = useUserContext();

  const hasAuth = !!(authInitialized && firebaseUser);
  const isOnboarded = !!(userInitialized && user && user?.hasBureauAuth);

  useEffect(() => {
    const code = new URLSearchParams(search).get('oobCode');
    const mode = new URLSearchParams(search).get('mode');

    if (code && authInitialized) {
      if (mode === 'resetPassword') {
        handleApplyActionCode(code);
        navigate(ROUTE_PATHS.RESET_PASSWORD + `?code=${code}`);
      }
    }
  }, [authInitialized, firebaseUser]);

  console.debug(
    'Checking Dashboard Redirect Requirements',
    hasAuth,
    isOnboarded
  );

  if (hasAuth && isOnboarded)
    return <Navigate to={ROUTE_PATHS.DASHBOARD} replace />;

  const isNativeHome =
    pathname === ROUTE_PATHS.HOME && Capacitor.isNativePlatform();

  if (Capacitor.isNativePlatform() && (!authInitialized || !userInitialized)) {
    return <LoadingPage />;
  }

  return (
    <>
      {!isNativeHome && <Header />}
      <SnackBarProvider>
        <Box
          mx={isMobile || isNativeHome ? 0 : 4}
          px={isMobile && !isNativeHome ? 3 : 0}
          style={{ overflowX: 'hidden', height: '100%' }}
          display="flex"
          flexGrow={1}
        >
          <Outlet />
        </Box>
      </SnackBarProvider>
      {!isNativeHome && <Footer />}
    </>
  );
};
