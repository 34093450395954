// Services
import { DEBT_GOAL } from '../../components/Optimizer/types';
import { RECOMMENDATION_ACTIONS } from './recommendation.actions';

export const initialState = {
  initialized: false,
  loading: false,
  [DEBT_GOAL.MAX_HOME]: null,
  [DEBT_GOAL.MIN_MONTHLY]: null,
  [DEBT_GOAL.MIN_TOTAL]: null,
  [DEBT_GOAL.GET_CASH]: null,
  plan: null,
  goal: null,
  creditAttempts: 0,
  recommendationAttempts: 0,
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case RECOMMENDATION_ACTIONS.SET_PLAN:
      return {
        ...state,
        [action.payload.goal]: action.payload.plan,
        plan: action.payload.plan,
        creditAttempts: 0,
        recommendationAttempts: 0,
        goal: action.payload.goal,
      };
    case RECOMMENDATION_ACTIONS.SWITCH_GOAL:
      return {
        ...state,
        plan: state[action.payload],
        goal: action.payload,
      };
    case RECOMMENDATION_ACTIONS.IS_INITIALIZED:
      return { ...state, initialized: true };
    case RECOMMENDATION_ACTIONS.CREDIT_ATTEMPT:
      return { ...state, creditAttempts: state.creditAttempts + 1 };
    case RECOMMENDATION_ACTIONS.RECOMMENDATION_ATTEMPT:
      return {
        ...state,
        recommendationAttempts: state.recommendationAttempts + 1,
      };
    case RECOMMENDATION_ACTIONS.SET_LOADING:
      return { ...state, loading: action.payload };
    case RECOMMENDATION_ACTIONS.INVALIDATE_CACHE:
      const nextState = {
        ...initialState,
        initialized: true,
      };

      if (action.payload) {
        nextState[DEBT_GOAL.MIN_MONTHLY] = state[DEBT_GOAL.MIN_MONTHLY];
        nextState[DEBT_GOAL.MIN_TOTAL] = state[DEBT_GOAL.MIN_TOTAL];
        nextState[DEBT_GOAL.MAX_HOME] = state[DEBT_GOAL.MAX_HOME];
        nextState[action.payload] = null;
      }

      return nextState;
    case RECOMMENDATION_ACTIONS.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
