/**
 * React and friends for the page component
 */
import React from 'react';

/**
 * Render the application
 */
import { render } from 'react-dom';

import './services/firebase.service';
import './analytics/logrocket';

/**
 * Set up error and performance analytics
 */
import './analytics/segment';
import './analytics/sentry';

/**
 * Application Component
 */
import App from './App';

/**
 * Providers for the application
 */

render(<App />, document.getElementById('root'));
