import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ROUTE_PATHS } from '../components/_pages/_utils/router.utils';

const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      if (event.url.includes('firebaseauth')) return;

      const url = new URL(event.url);
      const slug = url.pathname;

      if (slug && Object.values(ROUTE_PATHS).includes(slug)) {
        navigate(slug);
      } else {
        navigate('/');
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
