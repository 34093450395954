import * as React from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { NavLink } from './SharedHeader';
import { isExternalLink } from '../utils';

const Dropdown = styled('ul')`
  display: none;
  position: absolute;
  top: 64px;
  left: -100%;
  min-width: 360px;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 16px 0 16px 0;
  & > span {
    padding: 0 24px 10px 24px;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 120%;
    text-transform: uppercase;
  }
`;

const Node = styled('ul')`
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  margin-right: 24px;
  list-style: none;
  height: 100%;
  & > li {
    align-self: center;
    cursor: pointer;
  }
  & > ${Dropdown} {
    display: none;
    visibility: hidden;
  }
  &:hover,
  &:focus {
    & > li {
      border-bottom: 3px solid #0286e7;
      margin-bottom: -3px;
    }
    & > ${Dropdown} {
      display: flex;
      visibility: visible;
    }
  }
  & > li:hover,
  & > li:focus {
    border-bottom: 3px solid #0286e7;
    margin-bottom: -3px;
    & + div {
      display: flex;
    }
  }
`;

const Title = styled('span')`
  font-size: 16px;
  line-height: 150%;
`;
const Subtext = styled('span')`
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
`;

const NavItem = styled('li')`
  box-sizing: border-box;
  list-style: none;
  &:hover {
    background-color: #e1e2e4;
  }
  a {
    text-decoration: none;
  }
  & a,
  a:active,
  a:visited,
  a:hover {
    display: flex;
    flex-direction: column;
    padding: 6px 24px 16px 24px;
    color: #000;
  }
  & a > svg {
    margin-right: 32px;
  }
`;

export type TNavNodeChild = {
  to: string;
  title?: string;
  subtext?: string;
};

export const NavNode = ({
  title,
  subtext,
  nodes,
}: {
  title?: string;
  subtext?: string;
  nodes: TNavNodeChild[];
}) => (
  <Node tabIndex={-1} key={title}>
    {title && (
      <>
        <li tabIndex={0} role="menuitem" aria-haspopup="true">
          {title}
        </li>
        <KeyboardArrowDownIcon />
      </>
    )}
    <Dropdown role="menu" className={'nav-dropdown'}>
      {subtext && <span role="none">{subtext}</span>}
      {nodes.map((node) => (
        <NavItem key={`${node.to}-link`} role="none">
          {isExternalLink(node.to) ? (
            <a href={node.to}>
              <Title>{node.title}</Title>
              {node.subtext && <Subtext>{node.subtext}</Subtext>}
            </a>
          ) : (
            <NavLink to={node.to} role="menuitem">
              <Title>{node.title}</Title>
              {node.subtext && <Subtext>{node.subtext}</Subtext>}
            </NavLink>
          )}
        </NavItem>
      ))}
    </Dropdown>
  </Node>
);
