import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../styles/GlobalStyle';
import { ZeroStylesButton } from '../ZeroStylesButton';

export const Spacer = styled('div')`
  margin-top: 48px;
`;
export const Row = styled('div')<{ gap?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ gap }) => (gap ? `gap: ${gap}px` : '')};
`;

export const Column = styled('div')<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  ${({ gap }) => (gap ? `gap: ${gap}px` : '')};
`;

export const DesktopRow = styled(Column)`
  @media ${theme.mq.desktopLargeMin} {
    display: flex;
    flex-direction: row;
  }
`;

export const SectionContainer = styled('div')`
  display: flex;
  max-width: 1200px;
  box-sizing: border-box;
  flex-direction: column;

  @media ${theme.mq.desktopLargeMin} {
    flex-direction: row;
  }
`;

export const GridContainer = styled('div')`
  width: 100%;
  display: flex;
  position: relative;
`;

export const ButtonLink = styled(ZeroStylesButton)<{
  bgColor?: string;
  color?: string;
}>`
  font-family: 'Majorant-Medium';
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 18px;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  min-width: 227px;
  color: ${({ color, bgColor, theme }) =>
    color ? color : bgColor ? '#fff' : theme.blue4};
  &:hover {
    filter: brightness(94%);
  }
`;

export const SectionTitle = styled('h3')`
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    font-size: 64px;
  }
  line-height: 120%;
  text-align: center;
  color: #0b426a;
  margin-bottom: 54px;
`;

export const Paragraph = styled('p')`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  @media ${({ theme }) => theme.mq.desktopLargeMin} {
    font-size: 24px;
  }
  line-height: 150%;
  text-align: center;
  color: #101010;
`;

export const Bold = styled('span')`
  font-weight: bold;
`;

const PaddedLink = styled('a')`
  padding: 4px 4px 4px 0;
  color: ${({ theme }) => theme.gray8};
`;

export const UnbiasedResources = ({ isMobile }: { isMobile?: boolean }) => {
  const containerRef = React.useRef(null);
  const [height, setHeight] = React.useState(400);
  React.useEffect(() => {
    setHeight(containerRef.current?.clientHeight + 58);
  }, [isMobile, containerRef.current]);
  return (
    <Column
      style={{ alignItems: 'center', maxWidth: 783, margin: 'auto' }}
      ref={containerRef}
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          height,
          width: '100vw',
          backgroundColor: '#BFE4FF',
          zIndex: -2,
        }}
      />
      <SectionTitle>
        Are you a debt optimizer? More unbiased resources for you to organize
        your debt and borrow better
      </SectionTitle>
      <DesktopRow
        style={{
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '97%',
        }}
      >
        <Column>
          <PaddedLink href="https://www.solve.finance/resources/faq/">
            Solve Finance FAQ →
          </PaddedLink>
          <PaddedLink href="https://www.solve.finance/resources/how-to-figure-out-best-mortgage/">
            Get the best mortgage for you →
          </PaddedLink>
        </Column>
        <Column>
          <PaddedLink href="https://www.solve.finance/resources/plain-english/">
            Lower your mortgage cost →
          </PaddedLink>
          <PaddedLink href="https://www.solve.finance/independent-mortgage-advice/">
            Home Finance Tools →
          </PaddedLink>
          <Spacer />
          <PaddedLink
            href="https://calendly.com/seansolvefinance"
            target="_blank"
            rel="noreferrer nofollow"
          >
            Still have questions? Email us directly.
          </PaddedLink>
        </Column>
      </DesktopRow>
      <Spacer />
    </Column>
  );
};
