import React from 'react';

// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// Data
import { APP_TITLE } from '../../../../_configuration';

// Components
import { Text } from '../../../_core/typography.components';
import { IMAGES, getImage } from '../../../../utils';

const logo = getImage(IMAGES.SOLVE_LOGO_BLACK_TEXT.ref);

const LoadingPage = () => (
  <Container>
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Box mt={8}>
          <img src={logo} alt={APP_TITLE} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Text large align="center">
          Loading...
        </Text>
      </Grid>
    </Grid>
  </Container>
);

export default LoadingPage;
