import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Services
import { createRouter } from './components/_pages/_routes';
import { AuthProvider } from './providers/auth.provider';
import { UserProvider } from './providers/user/user.provider';
import { RecommendationProvider } from './providers/recommendations/recommendation.provider';
import { SnackBarProvider } from './components/Snackbar/useSnackBar';
import { OnboardingProvider } from './providers/onboarding/onboarding.provider';
import { AdditionalInfoProvider } from './providers/additional-information.provider';

// Components
import GlobalStyle, { theme } from './components/styles/GlobalStyle';

if (Capacitor.isNativePlatform()) {
  try {
    Keyboard.setAccessoryBarVisible({ isVisible: false });
  } catch {}
  try {
    Keyboard.setScroll({ isDisabled: false });
  } catch {}
}

const Main: React.FC = () => {
  const router = createRouter();

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
};

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>
        <UserProvider>
          <OnboardingProvider>
            <SnackBarProvider>
              <RecommendationProvider>
                <AdditionalInfoProvider>
                  <Main />
                </AdditionalInfoProvider>
              </RecommendationProvider>
            </SnackBarProvider>
          </OnboardingProvider>
        </UserProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
};

export default App;
