import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import SnackbarMessage from './SnackbarMessage';

interface ISnackBar {
  addMessage: (
    alert?: string | React.ReactElement,
    messageStatus?: boolean,
    duration?: number
  ) => void;
}

const GeneralContext = createContext({} as ISnackBar);

const { Provider, Consumer } = GeneralContext;

export const SnackBarConsumer = Consumer;

export function SnackBarProvider({ children }: { children: React.ReactNode }) {
  const [message, setMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isSuccess, setIsStatus] = useState(false);
  const [duration, setDuration] = useState(3000);

  const addMessage = useCallback((alert, success = true, duration = 3000) => {
    if (!alert) {
      setIsAlertVisible(false);
      return;
    }
    setIsStatus(success);
    setMessage(alert);
    setDuration(duration);
    setIsAlertVisible(true);
  }, []);

  const value = useMemo(() => ({ addMessage }), [addMessage]);

  return (
    <Provider value={value}>
      {children}

      <SnackbarMessage
        isAlertVisible={isAlertVisible}
        setIsAlertVisible={setIsAlertVisible}
        message={message}
        isSuccess={isSuccess}
        autoHideDuration={duration}
      />
    </Provider>
  );
}

export default () => useContext(GeneralContext);
