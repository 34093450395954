import React from 'react';
import styled from 'styled-components';

import { ThemeProvider } from '@mui/styles';

import theme from '../../theme';
import { theme as globalTheme } from '../../components/styles/GlobalStyle';

export const Caption: React.FC<{ gutterBottom?: boolean }> = ({
  children,
  gutterBottom,
}) => (
  <ThemeProvider theme={theme}>
    <p style={{ margin: gutterBottom ? '0 0 8px' : 0 }}>{children}</p>
  </ThemeProvider>
);

export const List = styled('ul')`
  margin: 0 0 16px 0;
`;

interface TextProps {
  align?: string;
  large?: boolean;
}

export const Text = styled('p')<TextProps>`
  color: rgba(0, 0, 0, 0.56);
  text-align: ${(props) => props.align || 'inherit'};
  font-size: ${(props) => !props.large && '16px'}${(props) => props.large && '20px'};
`;

export const MailLink = styled('a')`
  color: ${globalTheme.gray8};
  &:hover,
  &:active,
  &:focus,
  &:visited {
    color: ${globalTheme.gray8};
  }
`;
