import createTheme from '@mui/material/styles/createTheme';

export const MOBILE_BREAKPOINT = 767;

const theme = createTheme({
  palette: {
    primary: {
      main: '#0286E7',
    },
    secondary: {
      main: '#7e848a',
    },
  },
  typography: {
    fontFamily: 'Majorant-Regular',
    fontSize: 16,
    h1: {
      fontSize: '40px',
      fontWeight: 'bold',
      [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
        fontSize: '24px',
      },
    },
    h4: {
      fontSize: '23px',
      fontWeight: 'bold',
      color: '#333',
    },
  },
});

export default theme;
