import * as React from 'react';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { ROUTE_PATHS } from '../../_pages/_utils/router.utils';

const linkStyles = css`
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.gray8};
  text-decoration: none;
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const NavLink = styled(Link)<{ active: 'true' | 'false' }>`
  ${linkStyles}
  &::after {
    display: ${({ active }) => (active === 'true' ? 'block' : 'none')};
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.blue4};
    left: 0;
    bottom: -2px;
  }
`;

const ExternalLink = styled('a')`
  ${linkStyles}
`;

const items = [
  { displayName: 'Home', link: ROUTE_PATHS.DASHBOARD, type: 'internal' },
  {
    displayName: 'Debt',
    link: ROUTE_PATHS.MY_DEBTS,
    type: 'internal',
  },
  {
    displayName: 'Credit Score',
    link: ROUTE_PATHS.CREDIT_SCORE,
    type: 'internal',
  },
];

const NavItems = ({ className }: { className?: string }) => {
  const { pathname } = useLocation();

  return (
    <div className={className}>
      {items.map(({ displayName, link, type }, index) =>
        type === 'internal' ? (
          <NavLink
            to={link}
            active={pathname === link ? 'true' : 'false'}
            key={`${displayName}-${index}`}
          >
            {displayName}
          </NavLink>
        ) : (
          <ExternalLink
            href={link}
            key={`${link}-${index}`}
            target="_blank"
            rel="noreferrer"
          >
            {displayName}
          </ExternalLink>
        )
      )}
    </div>
  );
};

export default styled(NavItems)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 56px;
`;
