import React from 'react';
import { useLocation } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

// Utils
import { scrollToTop } from '../utils';

export const usePageChange = () => {
  const { pathname } = useLocation();
  const isNative = Capacitor.isNativePlatform();

  React.useEffect(() => {
    scrollToTop();

    if (window && window.analytics) {
      window.analytics.page();
    }

    if (Capacitor.isNativePlatform() && window?.Intercom) {
      window?.Intercom('shutdown');
    }

    const display = pathname === '/' && isNative ? 'none' : 'block';
    const elIntercomLW = document.getElementsByClassName(
      'intercom-lightweight-app'
    );

    for (let i = 0; i < elIntercomLW.length; i++) {
      if (elIntercomLW.length > i && elIntercomLW[i]) {
        elIntercomLW[i].setAttribute('style', `display: ${display}`);
      }
    }

    const elIntercomApp = document.getElementsByClassName('intercom-app');

    for (let i = 0; i < elIntercomApp.length; i++) {
      if (elIntercomApp.length > i && elIntercomApp[i]) {
        elIntercomApp[i].setAttribute('style', `display: ${display}`);
      }
    }
  }, [pathname]);
};
