// Buttons
export const createAccount = 'create-account-btn';
export const signIn = 'sign-in-btn';
export const forgotPassword = 'forgot-password-btn';
export const startOnboarding = 'start-onboarding-btn';
export const resetPassword = 'reset-password-btn';

// Inputs
export const emailField = 'email-input';
export const phoneNumber = 'phone-input';
export const password = 'password-input';
export const confirmPassword = 'confirm-password-input';

// Toggles
export const solveEmails = 'solve-emails-toggle';
export const solveSMS = 'solve-sms-toggle';
