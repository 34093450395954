import React from 'react';
import styled from 'styled-components';

type ButtonProps = {
  onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  className?: string;
  type?: 'submit' | 'button';
};

const Button = styled('button')`
  font-family: 'Majorant-Medium';
  background-color: transparent;
  border: none;
  border-width: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
`;
/**
 * Button with styles removed for wrapping things that need to be clickable
 * and have all of the accessibility benefits of a button
 * **/

export const ZeroStylesButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => <Button {...props}>{children}</Button>;
