import { useState, useEffect } from 'react';

// This is returning only width, because on native browsers, height changes when search bar
export const useWindowWidth = () => {
  const hasWindow = typeof window !== 'undefined';
  // Set default values just in case
  const [windowState, setWindowState] = useState({
    width: hasWindow ? window.innerWidth : 1200,
    isMobile: hasWindow ? window.innerWidth <= 767 : false,
    isTablet: hasWindow ? window.innerWidth <= 1079 : false,
  });

  const changeWindowSize = () => {
    if (windowState.width !== window.innerWidth) {
      setWindowState({
        width: window.innerWidth,
        isMobile: window.innerWidth <= 767,
        isTablet: window.innerWidth <= 1079,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);
    changeWindowSize();
    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);
  return windowState;
};

export const getViewportType = () => {
  const width = window.innerWidth;

  if (width <= 767) {
    return 'mobile';
  } else if (width <= 1079) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};
