import styled from 'styled-components';

export const PhoneInputStyles = styled('div')`
  position: relative;
  padding-bottom: 32px;
  width: 100%;
  &. PhoneInput {
    width: 100%;
    display: block;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: 24px;
  }
  & .PhoneInputCountry {
    display: none !important;
  }
  & .PhoneInputInput {
    font-size: 1rem;
    color: #000;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.1876em;
    box-sizing: border-box;
    height: 56px;
    border: 2px solid #000;
    border-radius: 4px;
    width: 100%;
    padding: 18.5px 14px;
    text-align: left;
  }
`;
