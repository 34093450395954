import { DEBT_GOAL } from '../../components/Optimizer/types';

export const ONBOARDING_ACTIONS = {
  HYDRATE: 'HYDRATE',
  SET_INITIALIZED: 'SET_INITIALIZED',
  SET_GOAL: 'SET_GOAL',
  SET_LIVING_SITUATION: 'SET_LIVING_SITUATION',
  SET_PERSONAL_INFO: 'SET_PERSONAL_INFO',
  SET_INCOME_INFO: 'SET_INCOME_INFO',
  SET_CONSENT_INFO: 'SET_CONSENT_INFO',
  SET_SEND_EMAIL: 'SET_SEND_EMAIL',
  SET_SEND_SMS: 'SET_SEND_SMS',
  RESET_STATE: 'RESET_STATE',
  SET_LOADING: 'SET_LOADING',
  SET_SOURCE: 'SET_SOURCE',
  SET_PHONE: 'SET_PHONE',
  HAS_FATAL_ERROR: 'HAS_FATAL_ERROR',
};

export const setGoal = (payload: { goal: string }) => ({
  type: ONBOARDING_ACTIONS.SET_GOAL,
  payload,
});

export interface IOnboardingData {
  hasFatalError: boolean;
  loading: boolean;
  initialized: boolean;
  firebaseRef?: string;
  goal: DEBT_GOAL;
  sendEmail: boolean;
  primaryPhone: string;
  sendSMS: boolean;
  source: string;
  dob: string | null;
  livingSituation: ILivingSituation;
  income: IIncome;
  personalInfo: IPersonalInfo;
  bureauProviderRef?: string;
  isSubscribed: boolean;
}

export const setInitialized = (payload: boolean) => ({
  type: ONBOARDING_ACTIONS.SET_INITIALIZED,
});

export const hydrateOnboardingData = (payload: IOnboardingData) => ({
  type: ONBOARDING_ACTIONS.HYDRATE,
  payload,
});

export const hasFatalError = () => ({
  type: ONBOARDING_ACTIONS.HAS_FATAL_ERROR,
});

interface ILivingSituation {
  isRenting: boolean;
  homeValue: number;
  rent: number;
}

export const setLivingSituation = (payload: ILivingSituation) => ({
  type: ONBOARDING_ACTIONS.SET_LIVING_SITUATION,
  payload,
});

interface IAddress {
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

interface IPersonalInfo {
  firstName: string;
  lastName: string;
  address: IAddress;
  primaryPhone?: string;
}

export const setPersonalInfo = (payload: IPersonalInfo) => ({
  type: ONBOARDING_ACTIONS.SET_PERSONAL_INFO,
  payload,
});

interface IIncome {
  annual: number;
  savings: number;
}

export const setIncomeInfo = (payload: IIncome) => ({
  type: ONBOARDING_ACTIONS.SET_INCOME_INFO,
  payload,
});

export const setSendEmail = (payload: boolean) => ({
  type: ONBOARDING_ACTIONS.SET_SEND_EMAIL,
  payload,
});

export const setSendSMS = (payload: boolean) => ({
  type: ONBOARDING_ACTIONS.SET_SEND_SMS,
  payload,
});

export const resetState = () => ({
  type: ONBOARDING_ACTIONS.RESET_STATE,
});

export const setLoading = (payload: boolean) => ({
  type: ONBOARDING_ACTIONS.SET_LOADING,
  payload,
});

interface IConsentInfo {
  dob: string;
}

export const setConsentInfo = (payload: IConsentInfo) => ({
  type: ONBOARDING_ACTIONS.SET_CONSENT_INFO,
  payload,
});

export const setPhone = (payload: { primaryPhone: string }) => ({
  type: ONBOARDING_ACTIONS.SET_PHONE,
  payload,
});

export const setSource = (payload: string) => ({
  type: ONBOARDING_ACTIONS.SET_SOURCE,
  payload,
});
