import React from 'react';
import { useLocation } from 'react-router';

// Components
import ButtonStyled from '../Button';

export const StripeRedirectPage = () => {
  const { search } = useLocation();
  const status = new URLSearchParams(search).get('status');

  if (status === 'success') {
    return (
      <div style={{ maxWidth: '400px', textAlign: 'center', margin: '0 auto' }}>
        <h3>Sucessfully subscribed. Click below to continue sign up</h3>
        <ButtonStyled href="https://app.solve.finance/onboarding/subscription?status=success">
          Continue
        </ButtonStyled>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '400px', textAlign: 'center', margin: '0 auto' }}>
      <h3>
        We were unable to complete your subscription. Click below to return to
        the app
      </h3>
      <ButtonStyled href="https://app.solve.finance/onboarding/subscription?status=cancelled">
        Back to App
      </ButtonStyled>
    </div>
  );
};
