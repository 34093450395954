export enum INCOME_TYPE {
  PRIMARY_ANNUAL = 'PRIMARY_ANNUAL',
}

export enum EXPENSE_TYPE {
  PRIMARY_RENT = 'PRIMARY_RENT',
  ALIMONY = 'ALIMONY',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
  OTHER_RENT = 'OTHER_RENT',
}

export enum ASSET_TYPE {
  HOME = 'HOME',
  VEHICLE = 'VEHICLE',
  LIQUID = 'LIQUID',
}
